<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="success"
          inline
          title="Gestion des inscriptions"
          class="px-5 py-3"
        >
          <v-data-table
            :headers="headers"
            :items="usagers"
            data-cy="dt-inscriptions"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                class="px-2 ml-1 primary"
                min-width="0"
                small
                @click="onProgression(item)"
              >
                <v-icon>
                  mdi-progress-check
                </v-icon>
              </v-btn>
              <v-btn
                class="px-2 ml-1 primary"
                min-width="0"
                small
                @click="onEditer(item)"
              >
                <v-icon
                  small
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                v-confirm="{ok: dialog => onEffacer(item)}"
                class="px-2 ml-1 warning"
                min-width="0"
                small
              >
                <v-icon
                  small
                >
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <download-excel
            :data="usagers"
            :fields="json_fields"
            name="Inscriptions.xls"
          >
            <v-btn
              class="primary"
            >
              Exporter
              <v-icon
                dark
                medium
                right
              >
                mdi-file-excel-outline
              </v-icon>
            </v-btn>
          </download-excel>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import restApiService from '@/services/restApiService.js'

  export default {
    data: () => ({
      usagers: [],
      headers: [
        { text: 'Usager', value: 'nomUsager' },
        { text: 'Inscriptions', value: 'dateCreation' },
        { text: 'Nom', value: 'nom' },
        { text: 'Prénom', value: 'prenom' },
        { text: 'Courriel', value: 'courriel' },
        { text: 'Actions', value: 'actions' },
      ],
      json_fields: {
        Usager: 'nomUsager',
        'Date inscription': 'dateCreation',
        Nom: 'nom',
        Prénom: 'prenom',
        Courriel: 'courriel',
      },
    }),
    mounted () {
      this.charger()
    },
    methods: {
      charger () {
        restApiService.get('/api/usagers').then((res) => {
          this.usagers = res.data
        })
      },
      onEditer (usager) {
        // this.$router.push({ path: 'editionScenario/' + scénario.id })
      },

      onEffacer (usager) {
        restApiService.delete('/api/usagers/' + usager.id)
        this.charger()
      },
      onProgression (usager) {
        this.$router.push({ path: 'progressionUsager/' + usager.id })
      },
    },
  }
</script>
